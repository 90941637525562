// prettier-ignore
export const ms = {
  buttons: {
    add_payment_receipt: 'Masukan Resit Pembayaran',
    add_towing_details: 'Masuk Butiran Towing',
    add_watchlist: 'Tambah ke senarai pantuan',
    apply: 'Tetapkan',
    attend: 'Hadir',
    back_review: 'Kembali & Semak',
    back_to_login: 'Kembali ke Log Masuk',
    back_to_sign_in: 'Kembali ke Log Masuk',
    back: 'Kembali',
    bid_no_sale: 'Tiada Jualan',
    bid_now: 'Bida Sekarang',
    buyer_registration: 'Pendaftaran Pembeli',
    cancel: 'batal',
    change_password: 'Tukar Kata Laluan',
    choose_from_library: 'Pilih dari galeri',
    clear_filters: 'Set semula tapisan',
    clear: 'Set semula',
    close: 'Tutup',
    confirm_submit: 'Sahkan dan Serahkan',
    confirm: 'Sahkan',
    contact_us_now: 'Hubungi kami sekarang',
    contact_us: 'Hubungi kami',
    continue: 'Teruskan',
    create_account: 'Buat akaun',
    deposit_new: '+',
    deposit: 'Tambah Nilai Deposit',
    details: 'Butiran',
    directions: 'Arah',
    done: 'Selesai',
    download_list: 'Muat Turun Senarai',
    download: 'muat turun',
    e_bidding: 'eBidaan',
    filters: 'Turasan',
    forgot_password: 'Lupa Kata Laluan?',
    get_started: 'Mulakan',
    go_back: 'Kembali',
    live_auction: 'Lelong Secara Langsung',
    log_in: 'Log Masuk',
    logout: 'Log Keluar',
    make_payment: 'Hantar Slip Pembayaran',
    menu: 'Menu',
    new_item: 'Item Baru',
    next: 'Seterusnya',
    offer: 'Tawaran',
    ok: 'Baik',
    pre_bid_now: 'Prebid sekarang',
    pre_bid: 'Prabidaan',
    proceed: 'Teruskan',
    record_again: 'Merekodkan Semula',
    register: 'Daftar',
    remove_watchlist: 'Keluarkan / Alihkan daripada senarai pantuan',
    reset_password: 'Menetapkan semula kata laluan',
    reset: 'Set semula',
    retake: 'Mengambil semula',
    save_note: 'Simpan catatan',
    save: 'Simpan',
    search: 'Cari',
    sell_with_us: 'Jual dengan kami',
    send_email: 'hantar e-mel',
    send_message: 'Send message',
    set_max_bid: 'Set auto bida maksimum',
    set_pre_bid: 'Set Pra Bidaan',
    show_all: 'Tunjuk Semua',
    sign_in: 'Log masuk',
    sign_up: 'Daftar',
    skip: 'Langkau',
    sold: 'Dijual ({{badge}})',
    submit: 'Hantar',
    switch_environment: 'Switch Environment',
    take_photo: 'Mengambil Gambar',
    top_up: 'Tambah Nilai Sekarang!',
    update_bid: 'Mengemaskinikan Tawaran',
    update_max_bid: 'Mengemaskinikan bida maksimum',
    update: 'Kemas kini',
    upload: 'Muat naik',
    view_all_auctions: 'View All Auctions',
    view_all_items: 'View All Assets',
    view_all: 'Semak Semua',
    view_details: 'Semak Butiran',
    view: 'Rujuk',
    watchlist: 'Senarai pantau',
    withdraw: 'Tarik Balik',
    withdrawn: 'Dibatalkan',
  },
  labels: {
    _balance: 'Baki',
    '03WD': '3 hari perniagaan',
    '05D': '5 hari',
    '05WD': '5 hari perniagaan',
    '07D': '7 hari',
    '07WD': '7 hari perniagaan',
    '10D': '10 hari',
    '14D': '14 hari',
    '30D': '30 hari',
    // TODO: industrial_auction_list:
    // TODO: salvage_auction_list:
    account_type_desc: 'Lorem ipsum dolor sit amet adiliscing consectetur', //TODO tukar teks
    account_type_header: 'Jenis Akaun',
    account_type_titel: 'Pilih jenis akaun anda',
    actions: 'TINDAKAN',
    add_money: 'Tambah nilai',
    add_note: 'Tambah Nota',
    add_photo: 'Penambahan foto',
    added_to_watchlist: 'Tambah ke senarai pantau',
    agree: 'Saya bersetuju dengan ',
    all_deposits_refundable: 'Deposit dikembalikan 100% jika anda tidak berjaya memenangi.',
    amount_amt: 'Jumlah perlu dibayar',
    amount_caps: 'JUMLAH',
    amount_left: 'Jumlah yang tinggal',
    amount_paying: 'Jumlah yang dibayar',
    amount: 'Amount',
    applied: 'Digunakan',
    approved: 'Diluluskan',
    at_least_8_characters: 'Kata laluan mestilah sekurang-kurangnya mengandungi 8 karakter.',
    auction_ended: 'Bidaan tamat',
    auction_live: 'Bid Dijalankan',
    auction_remarks: 'Kenyataan Lelong',
    auction: 'Lelong',
    available_balance: 'Baki yang ada',
    badge: 'Lencana',
    balance_available_new: 'Tersedia',
    balance_available: '{{value}} Baki yang ada',
    balance_hold_new: ' masih dipegang',
    balance_hold: '{{value}} masih dipegang',
    balance: 'Baki Akuan',
    be_first: 'Jadilah yang pertama!',
    begin_shortly: 'Sesi lelong akan bermula sebentar',
    bid_increment: 'Kenaikan Bid',
    bid_not_available: 'Bidaan tidak tersedia',
    bid_this_amount: 'bida amoun ini',
    bidding_bid_won: 'Menang Bidaan',
    bidding_view_auctions: 'Lihat Lelongan',
    bidding_disabled: 'Bidaan tidak aktif',
    bidding_enabled: 'Bidaan aktif',
    bidding_ended: 'Bidaan Telah selesai',
    bidding_hold: 'Dipegang',
    bidding_live: 'Bidaan secara langsung',
    bidding_lost: 'Tidak berjaya',
    bidding_lotted: 'Dilotkan',
    bidding_offer: 'Tawaran',
    bidding_outbid: 'Bidaan lebih rendah',
    bidding_unknown: 'Tidak diketahui',
    bidding_highest_bidder: 'Penawar tertinggi',
    bidding_winning: 'Menang',
    bidding_won: 'Anda pemenang!',
    blocked_amount: 'amaun yang disekat',
    blocked_balance_alt: 'Baki tersekat',
    blocked_balance: 'Disekat',
    blocked_deposit_lowercase: 'Deposit disekat',
    blocked_deposit: 'Deposit disekat',
    brn_id_my: 'BRN (No. Reg Perniagaan dalam format 12 digit)',
    brn_id: 'Nombor Pendaftaran Perniagaan',
    broker: 'Broker (Saya orang tengah)',
    build: 'Build',
    by_location: 'Mengikut lokasi',
    by_make: 'Mengikut jenama',
    by_model: 'Mengikut model',
    by_type: 'Mengikut jenis',
    cant_find: 'Tiada nombor kenderaan dijumpai "{{value}}".',
    car_auction: 'Senarai lelong kereta terpakai',
    category: 'Kategori',
    change_img: 'Tukar imej',
    change_password_lowercase: 'Tukar kata laluan',
    change_password: 'Tukar Kata Laluan',
    chassis_number: 'Nombor Casis',
    check_image: 'Check image quality',
    check_your_mail: 'Sila semak emel anda',
    choose_identity_card: 'Pilih kad pengenalan',
    choose_payment_proof: 'Pilih Bukti Pembayaran',
    closed: 'Tamat',
    color: 'Warna',
    condition_type: 'Jenis Keadaan',
    condition: 'Keadaan',
    congratulation: 'Taniah',
    contain_lowercase_case: 'Kata laluan anda mesti mengandungi sekurang-kurangnya satu huruf kecil.',
    contain_upper_case: 'Kata laluan anda mesti mengandungi sekurang-kurangnya satu huruf besar',
    contract: 'Kontrak',
    copy_link: 'Atau salin pautan',
    create_account: 'Buat akaun',
    currency_rm: 'RM',
    current_balance: 'Baki semasa',
    current_bid_lowercase: 'bid semasa',
    current_bid: 'Bida sekarang',
    current_status: 'Status Semasa',
    date: 'Tarikh',
    dealer_broker_info: 'Saya seorang pembeli/penjual profesional',
    dealer: 'Dealer (Saya adalah pembeli profesional)',
    deals_money: 'Find Deals. Make Money.', //TODO: update
    deposit_amount: 'Deposit',
    deposit: 'Deposit',
    device_unique_id: 'Peranti UID',
    disabled: 'Tidak Aktif',
    documents: 'Dokumen',
    dont_have_account: 'Tiada akaun? ',
    dot: ' • ',
    drawer_about: 'Tentang Kita',
    drawer_auctions: 'Lelong',
    drawer_e_bidding_auction: 'Lelongan eBidaan',
    drawer_ebids: 'eBida Saya',
    drawer_faq: 'Soalan Lazim',
    drawer_help_and_support: 'Bantuan & Sokongan',
    drawer_live_auction: 'Lelong secara langsung',
    drawer_my_bids: 'eBidaan Saya',
    drawer_offers_history: 'Penawaran Saya',
    drawer_prebids: 'Prabidaan Saya',
    drawer_purchase_history: 'Rekod Pembelian Saya',
    drawer_purchase: 'Pembelian Saya',
    drawer_purchase_v2: 'Pembelian & Tawaran',
    drawer_search_inventory: 'Cari inventori',
    drawer_service: 'Servis Tambahan',
    drawer_terms_and_conditions: 'Terma dan Syarat',
    drawer_title: 'Navigasi',
    drawer_transactions: 'Rekod Transaksi Saya',
    drawer_watchlist: 'Senarai Pantau Saya',
    due_amt: 'Jumlah hutang',
    email_address: 'Alamat emel',
    email_not_valid: 'Masukkan emel yang betul',
    email_sent: 'E-mel telah dihantar',
    empty_fullname: 'Sila masukan nama penuh anda',
    empty_username: 'Sila masukan kata laluan yang betul',
    enabled: 'Aktif',
    end_in: 'Masa yang tinggal',
    end_time: 'Masa tamat',
    end: 'Tamat {{date}}',
    engine_number: 'Nombor Engin',
    enter_bid_amount: 'Masukan amoun bidaan',
    e_bidding: 'eBidaan',
    failed_deposit: 'Ralat yang tidak diketahui ({{error}}) berlaku semasa memproses transaksi anda. Sila hubungi bank anda atau cuba lagi. Sekiranya ralat berterusan, anda boleh menghubungi sokongan pelanggan kami di +60166999170.',
    filtered_items: 'item yang dituras',
    have_account: 'Sudah mempunyai akaun? ',
    hello_user: 'Helo {{name}},',
    high_bid: 'Bidaan tertinggi',
    highest_bid_amount: 'jumlah bida tertinggi',
    highest_bidder: 'Anda adalah pembida tertinggi',
    ic_required: 'Imej MyKad atau Passport diperlukan',
    id: 'ID',
    incident_type: 'Jenis Kejadian',
    incorp_country: 'Pemerbadanan Perniagaan Negara',
    increase_max_bid: 'Masukkan Bidaan Maksimum',
    inspection_remarks: 'Keterangan Pemeriksaan',
    inspection_report: 'Laporan pemeriksaan',
    insufficient_balance: 'Insufficient Balance',
    insufficient_deposit: 'Deposit tidak mencukupi',
    insurance_report: 'Laporan insurans kereta',
    intake_origin: 'Butiran Asal',
    item_details: 'Detail Butiran',
    item_live: 'Lelong secara langsung',
    item_name: 'Nama item',
    item_on_hold: 'Item masih dipegang',
    item_prebid_label: 'Parbidaan',
    item_static: 'eBidaan',
    item: 'Item',
    items: 'item',
    key: 'Kunci',
    last_bid: 'Bidaan terakhir',
    last_step_desc: 'Langgan senarai e-mel kami dan jadilah orang pertama yang mengetahui apa yang akan dijual.',
    last_step_header: 'Hampir siap atau hampir selesai',
    last_step_title: 'Jangan lepaskan peluang ini',
    latest_auctions: 'Latest Auctions',
    left: 'tinggal',
    live_auction_terms: 'Semua tawaran bergantung dan tertakluk pada Syarat dan Peraduan Lelong Pickles Auction',
    live_now: 'Secara Langsung',
    live: 'Live',
    LIVE: 'LIVE',
    location: 'Lokasi',
    lot_current_bid: 'Bidaan semasa',
    lot_deposit: 'Deposit',
    lot_is_on_hold: 'Item masih dipegang',
    lot_number: 'Lot',
    lot_starting_price: 'Harga Buka',
    lot_stock_number: 'Stok: {{stock}} •  Lot: {{lot}}',
    lost: 'TIDAK BERJAYA',
    make: 'Jenama',
    max_bid: 'Bidaan maksimum anda',
    model: 'Model',
    my_bids: 'Bid Saya',
    my_last_bid: 'Bid terakhir saya',
    my_last_bids: 'eBida saya',
    my_max_bid: 'Bid maksimum saya',
    my_prebid: 'Prabidaan saya',
    my_prebids:'Prebid Saya',
    my_watchlist: 'Senarai pantau saya',
    n_a: 'N/A',
    nationality: 'Kewarganegaraan',
    never: 'tidak pernah',
    new_added: 'BARU',
    next_bid_lowercase: 'bid seterusnya',
    next_bid: 'Bid Seterusnya',
    next_steps: 'Sila semak e-mel anda untuk invois',
    no_auctions_yet: 'Tiada rekod lelong',
    no_auctions: 'Tiada lelong buat masa sekarang',
    no_blocked_deposits: 'anda tiada deposit yang disekat',
    no_e_bidding_auctions: 'Tiada lelong eBidding buat masa sekarang',
    no_ebids_yet: 'Tiada rekod eBidaan',
    no_ebids: 'Tiada rekod eBidaan',
    no_fees: 'tanpa fi',
    no_items_yet: 'Tiada item',
    no_items: 'Tiada item untuk sesi lelong ini.',
    no_live_auctions: 'Tiada lelong Live buat masa sekarang',
    no_my_bids: 'Tiada rekod tawaran',
    no_offer_yet: 'Tiada rekod tawaran',
    no_offers: 'Tiada rekod tawaran',
    no_prebid_yet: 'Tiada rekod prabidaan',
    no_prebids: 'Tiada rekod prabidaan',
    no_price: 'RM -',
    no_purchase_yet: 'Tiada rekod pembelian',
    no_purchases: 'Tiada rekod pembelian',
    no_result_found: 'Tiada rekod',
    no_sale: 'tidak dijual',
    no_search_result: 'Tiada rekod',
    no_transactions: 'Tiada tranksasi',
    no_watchlist: 'Tiada item dalam senarai pantau',
    now_bidding: 'Bid Sekarang',
    offer_amount: 'Jumlah tawaran',
    offer_for: 'Tawaran untuk {{amount}}',
    offer_this_amount: 'ditawarkan pada amoun ini',
    offers: 'Tawaran',
    one_touch_bidding_disabled: 'One Touch Bidding Disabled',
    one_touch_bidding_enabled: 'One Touch Bidding Enabled',
    or: 'atau',
    paid: 'Dibayar',
    paid_on: 'Dibayar pada',
    partial_submitted: 'Sebahagian sahaja diserahkan',
    password_not_match: 'Kata laluan tidak sepadan',
    password_rules: 'Kata laluan anda mesti mengandungi sekurang-kurangnya 8 aksara, 1 huruf besar, 1 huruf kecil, dan 1 nombor!',
    password_to_switch_environment: 'Masukkan kata laluan untuk menukar persekitaran.',
    password: 'Kata laluan',
    pay_by: 'Bayar sebelum',
    pay_by_date: 'Bayar mengikut tarikh',
    payable_to_pickles: 'Dibayar kepada Pickles',
    payable_to_seller: 'Dibayar kepada Penjual',
    payment_due: 'Jumlah Yang Perlu Dibayar',
    payment_instructions: 'Arahan Pembayaran',
    payment_pending: 'Pembayaran belum selesai',
    payment_proof: 'Bukti pembayaran',
    payment_ref_no: 'Nombor Rujukan Pembayaran',
    payment_submission_history: 'Penyerahan Pembayaran',
    pending_account: 'Akaun masih ditahap kelulusan',
    pending_approval: 'Menunggu kelulusan',
    personal_details: 'Maklumat peribadi',
    personal_id: 'MyKad atau Pasport',
    phone_not_valid: 'Masukkan nombor telefon yang betul',
    please_fill_all_fields: 'sila isi senarai tersebut',
    prebid_label: 'Parbidaan',
    profile_details: 'Butiran profil',
    profile_info: 'Maklumat profil',
    profile: 'PROFIL',
    public_info: 'Saya beli/jual untuk diri sendiri atau keluarga',
    public: 'Public (Saya membeli untuk diri sendiri atau keluarga)',
    read_and_agree: 'Saya telah membaca dan bersetuju dengan',
    recent_industrial_items: 'Penambahan Aset Perindustrian baru ini',
    recent_salvage_items: 'Penambahan Aset Salvage baru ini',
    recent_used_cars: 'Penambahan Kereta Terpakai baru ini',
    recent_used_motorcycles: 'Penambahan Motosikal Terpakai baru ini',
    recommended_auctions: 'Disyorkan untuk anda',
    reg_as: 'Daftar sebagai',
    reg_number: 'Nombor Reg',
    register_complete: 'Anda telah siap sedia,',
    rejected: 'Ditolak',
    remember_me: 'Ingat saya',
    removed_from_watchlist: 'Padamkan dari senarai pantau',
    reserve: 'Rizab',
    reserved_price: 'Harga rizab',
    reset_password: 'Menetapkan semula kata laluan',
    search_our_inventory: 'Cari Inventori',
    select_payment_method: 'Sila pilih kaedah pembayaran anda',
    select_payments: 'Sila pilih salah satu daripada kaedah pembayaran berikut:',
    selected: 'Dipilih',
    sending_bid: 'Menghantar bidaan...',
    set_max_bid_lowercase: 'set auto bida maksimum',
    share_via: 'Kongsikan melalui',
    sign_in: 'Log masuk',
    sign_me_weekly: 'Ya, daftarkan saya untuk weekly',
    sign_me: 'Ya, daftarkan saya untuk',
    socket_id: 'Connection ID',
    sold_amt: 'Harga dijual',
    sold_date: 'Tarikh terjual',
    sold_on: 'Tarikh dijual',
    sold_this_amount: 'Jual pada amoun ini',
    sold: 'Dijual',
    sorry: 'Maaf!',
    source_type: 'Jenis sumber',
    specifications: 'Spesifikasi',
    sst_id: 'No Reg SST (Nombor Cukai Jualan dan Perkhidmatan)',
    start_in: 'mula pada',
    start_price: 'Harga buka',
    start_soon: 'Starts soon',
    start_time: 'Masa mula',
    starting_bid: 'Bida mula',
    STATIC: 'eBidding',
    status: 'Status Pembayaran',
    stock_no: 'Nombor Stok',
    stock_number: 'Stok',
    submitted: 'Diserahkan',
    supported_payment_methods: 'Kaedah pembayaran sedia ada',
    supporting_document: 'Dokumen sokongan #1',
    take_photo: 'Mengambil gambar',
    tap_to_bid: 'Klik untuk membida',
    thanks_for_attend: 'Terima kasih kerana menghadiri.',
    time_date: 'Masa/Tarikh',
    time_out: 'Masa tamat',
    time_remaining: 'Masa yang tinggal',
    time: 'Masa',
    tin_id: 'TIN (Nombor Maklumat Cukai)',
    to_be_announced: 'Akan diumumkan',
    today_lowercase: 'Hari ini',
    today: 'Hari ini',
    top_up_securely: 'Tambah nilai dengan selamat',
    top_up: 'Tambah Nilai',
    total_bids: 'Jumlah bid',
    total_items: 'jumlah item',
    tow_by: 'Tow sebelum',
    transaction_history_lowercase: 'Histori transaksi',
    transaction_history: 'Rekod Transaksi',
    transactions: 'TRANSAKSI',
    triple_dot: '•••',
    type_lowercase: 'Jenis',
    type_of_doc: 'Jenis dokumen',
    type: 'JENIS',
    units: 'Unit',
    unpaid: 'Belum Dibayar',
    upcoming_auctions: 'Lelong yang akan datang',
    update_require_message: 'Anda masih mengunakan aplikasi Pickles versi lama. Versi terkini menpunyai ciri baharu yang lebih baik supaya anda boleh terus sertai pembidaan secara online.',
    upload_avatar: '+ Muat naik foto profil',
    upload_id: 'Muat naik imej MyKad \natau Pasport',
    upload_receipt: 'Muat naik resit',
    uploaded_id: 'MyKad atau Pasport\n',
    used_motor_auction_list: 'Senarai lelong motosikal terpakai',
    useful_links: 'Here are some helpful links instead:',
    variant: 'Jenis',
    vendor: 'Penjual',
    version: 'versi',
    view_contract: 'Lihat Kontrak',
    view_history: 'Lihat sejarah',
    view_time: 'Masa memeriksa',
    welcome: 'Selamat datang',
    withdraw: 'Tarik Balik',
    yard_location: 'Lokasi gudang',
    years_of_manufacture: 'Tahun dibuat',
    you_are_logged_with: 'Anda log masuk dengan',
    you_available_deposit: 'Deposit saya',
    you_highest: 'Anda adalah pembidda tertinggi. Jika ingin meningkatkan bid maksimum anda, klik "Bid Maksimum Saya"',
    you_won: 'Anda Pemenang!',
    you: 'ANDA',
    your_bid: 'Bida anda',
    your_deposit_has_been_received: 'Deposit anda telah diterima oleh MolPay. Muat semula skrin jika baki baharu tidak ditunjukkan dalam masa 1 minit.',
    your_max_bid: 'Bida maksimum anda',
    your_notes: 'Nota anda',
    your_pre_bid: 'Prebid Anda',
    your_prebid_accepted: 'Bidaan maksimum {{amount}} telah diterima',
    your_wallet: 'Wallet saya',
  },
  captions: {
    be_first_bidder: 'Jadilah pembida pertama!',
    check_auctions: 'Lelongan tamat. Sila pergi ke halaman utama untuk lebih banyak lelongan!',
    eligible_for_offer: 'Layak untuk Tawaran!',
    eligible_to_buy: 'Layak untuk Membeli!',
    highest_bidder: 'Anda adalah pembida tertinggi!',
    late_bid_placed: 'Tawaran anda dibuat lewat',
    lost: 'Anda tidak berjaya!',
    lotted: 'Dilotkan',
    offer: 'Tawaran',
    outbid: 'Bidaan anda lebih rendah!',
    outbid_by_someone: 'Bidaan anda lebih rendah daripada orang lain',
    send_bid_delay: 'Tawaran anda mengambil masa lebih lama dari yang dijangkakan untuk diproses. Sila tunggu sebentar atau periksa sambungan rangkaian anda.',
    start_bidding: 'Mula Membida Sekarang!',
    top_up: 'Tambah nilai {{amountToTopUp}} untuk membida!',
    updating_result: 'Mengemas kini Keputusan Tawaran...',
    winning: 'Anda telah memenangi!',
    won: 'Anda pemenang!',
  },
  fields: {
    account_status: 'Status akaun',
    acc_type_business: 'Perniagaan',
    acc_type_individual: 'Individu',
    bid_now: 'Bida Sekarang',
    brand: 'Jenis',
    brn_id: 'Masukkan Nombor Pendaftaran Perniagaan',
    confirm_new_password: 'Sahkan Kata Laluan Baru',
    confirm_password: 'Sahkan kata laluan',
    current_password: 'Kata laluan semasa',
    dealer: 'Dealer (Saya adalah pembeli profesional)',
    email_address: 'Alamat emel',
    full_name: 'Nama penuh',
    ic_number: 'Nombor kad pengenelan',
    identity_card_selected: 'Kad Pengenalan telah dipilih',
    identity_card: 'Kad pengenelan',
    ill_purchasing_items_as_a: 'Saya sebagai pembeli.....',
    keyword: 'Kata kunci',
    location: 'Lokasi',
    make: 'Jenama',
    mobile_number: 'No. telefon',
    model: 'Model',
    name: 'Nama',
    new_email_address: 'Masukan emel anda',
    new_password: 'Kata laluan baru',
    passport_number: 'No. MyKad/Pasport',
    passport: 'Enter your IC number or Passport',
    password: 'Kata laluan',
    payment_ref_no: 'Masukkan rujukan pembayaran. #',
    phone_number: 'Nombor telefon',
    repeat_password: 'Ulang kata laluan',
    search_for_an_item: 'Cari item atau lelongan...',
    search_input: 'Cari item & lelong',
    search_watchlist: 'Cari senarai pantauan',
    search: 'Cari',
    select_auction_category: 'Kategori lelong',
    select_auction_type: 'Pilih jenis lelong',
    select_condition_type: 'Pilih jenis keadaan',
    select_incorp_country: 'Pilih Negara Pemerbadanan Perniagaan',
    select_item_type: 'Pilih jenis item',
    select_location: 'Pilih lokasi',
    select_make: 'Pilih jenama',
    select_model: 'Pilih model',
    select_nationality: 'Pilih Kewarganegaraan',
    select_type: 'Pilih jenis',
    sst_id: 'Masukkan nombor Cukai Jualan dan Perkhidmatan (SST)',
    tin_id: 'Masukkan nombor Maklumat Cukai',
    turn_notification: 'Matikan/hidupkan notifikasi',
    username: 'Nama pengguna',
    yard: 'Gudang',
    year_from: 'Tahun Dari',
    year_to: 'Tahun ke',
    year: 'Tahun',
    your_name: 'Nama anda',
  },
  infos: {
    account_pending_title_new: 'Akaun masih ditahap kelulusan',
    account_pending: 'Akaun anda masih dalam proses kelulusan. \nAnda akan menerima emel pengesahan sebentar lagi.',
    after_login: 'Anda akan dapat jumpa aktiviti eBidding di sini selepas anda log masuk.',
    amount_paying_not_exceed_outstanding: 'Amaun yang dibayar tidak boleh lebih daripada jumlah tertunggak',
    auction_not_started: 'Item ini boleh dijual tetapi lelongan belum bermula. Akan diberitahu bila sesi bidaan bermula, klik pada senarai pantaun seperti di atas. ',
    auction_started: 'Lelongan untuk item ini sudah bermula. Untuk membida item ini, anda mesti menghadiri sesi lelongan. Selamat membida!',
    check_payment_reference: 'Pastikan ID Rujukan pembayaran dan amaun adalah jelas.',
    contact_pickles_support: 'Hubungi Sokongan Pickles di {{phone}} untuk mendapatkan bantuan.',
    current_bid: 'Bid semasa',
    custom_bid_info_more_than: 'Dengan menetapkan Tawaran Maksimum, sistem kami akan menawarkan anda menentang penawar lain, sehingga tawaran maksimum anda tercapai. Untuk menetapkan Tawaran Maksimum, anda mesti menawarkan lebih tinggi dari {{nextValue}} agar tawaran anda diterima. Tawaran Maksimum dapat ditingkatkan pada masa akan datang jika dikehendaki.',
    custom_bid_info_prebid: 'Dengan menetapkan Tawaran Maksimum, sistem kami akan menawarkan anda menentang penawar lain, sehingga tawaran maksimum anda tercapai. Tawaran Maksimum dapat ditingkatkan pada masa akan datang jika dikehendaki.',
    custom_bid_info: 'Dengan menetapkan Tawaran Maksimum, sistem kami akan menawarkan anda menentang penawar lain, sehingga tawaran maksimum anda tercapai. Untuk menetapkan Tawaran Maksimum, anda mesti menawarkan sekurang-kurangnya {{nextValue}} atau lebih tinggi agar tawaran anda diterima. Tawaran Maksimum dapat ditingkatkan pada masa akan datang jika dikehendaki.',
    drawer_not_logged: 'Untuk menikmati akses penuh ke Pickles Auction, sila daftar akaun atau log masuk.',
    duplicate_payment_reference: 'Nombor Rujukan pembayaran ini sudah wujud. Sila serahkan nombor rujukan pembayaran yang berbeza.',
    email_sent: 'Kami telah menghantar e-mel untuk pertukaran kata laluan anda',
    ensure_tax_info_accurate_to_irb: 'Sila pastikan maklumat yang diberikan adalah tepat kerana ia akan diserahkan kepada LHDN/LHDN mengikut perundangan yang diperlukan',
    ensure_tax_info_accurate: 'Sila pastikan maklumat adalah tepat.',
    // eslint-disable-next-line quotes
    enter_na: `Hanya taip 'NA' dalam medan di atas`,
    exceed_items_per_submission: 'Anda hanya boleh menggunakan pembayaran sehingga maksimum {{numberOfItems}} item.',
    exceed_payment_reference_length: 'Nombor Rujukan Pembayaran tidak boleh melebihi {{allowedPaymentReferenceLength}} aksara.',
    forgot_pass: 'Kami akan menghantar e-mel untuk pertukaran kata laluan anda.',
    for_bank_details_and_contact: 'Butiran akaun bank dan maklumat hubungan',
    get_started: 'Mulakan',
    insufficient_balance_topup: 'Amaun baki deposit anda tidak mencukupi untuk membida, sila tambah nilai anda sebelum membida.',
    insufficient_deposit: 'Deposit tidak mencukupi',
    insufficient_balance: 'You have won this item with your deposit blocked in another auction. Please call {{phone}} to topup your balance and complete your purchase.',
    login_info: 'Anda mesti mempunyai baki yang mencukupi untuk item yang anda bida. Deposit minimum yang diperlukan untuk setiap item adalah seperti yang disenaraikan. Amaun yang betul akan segera ditolak dari akaun anda jika anda berjaya menjadi pembida.',
    message_sent_error: 'Error occurred while sending message!',
    message_sent: 'Your message has been sent!',
    message_subscribed: 'You have subscribed to our company newsletter',
    must_login_favorite: 'Anda mesti log masuk untuk menambah ke senarai pantau.',
    must_login_note: 'Anda mesti log masuk untuk menambah catatan.',
    must_login_to_bid: 'Anda mesti log masuk untuk membida.',
    no_brn: 'Tiada Nombor Pendaftaran Perniagaan?',
    no_longer: 'Item ini tiada lagi',
    no_sst: 'Tiada Cukai Jualan dan Perkhidmatan (SST)?',
    no_tin: 'Tiada nombor Maklumat Cukai?',
    not_logged: 'Ada belum log masuk. Sila log masuk untuk menghadiri sesi lelongan, membeli item dan lain-lain.',
    on_hold: 'Item ini masih dipegang / akan dijual untuk untuk lelongan di masa hadapan.',
    or_send: 'atau hantarkan e-mel kepada kami',
    payment_guideline_instruction_1: 'Anda boleh membuat pembayaran untuk maksimum 5 item pada satu masa',
    payment_guideline_instruction_2: 'Anda hanya boleh menghantar 1 resit sebagai bukti pembayaran',
    payment_notification: 'Anda mempunyai {{paymentTerm}} untuk membuat pembayaran bagi pembelian, jika gagal berbuat demikian mungkin dikenakan bayaran penalti storan lewat.',
    payment_success: 'Status akan dikemas kini dalam halaman item selepas pengesahan',
    payment_to_seller_important_note: 'Penting: bahagian ini hanya boleh digunakan untuk membuat pembayaran kepada Pickles.',
    payment_to_seller_instruction_intro: 'Cara membuat pembayaran penjual:',
    payment_to_seller_instruction_1: 'Sila pergi ke Pembelian & Tawaran > Belum Dibayar',
    payment_to_seller_instruction_2: 'Sila tekan pada "Lihat butiran" pada item untuk pembayaran',
    payment_to_seller_instruction_3: 'Semak "Lihat kontrak" untuk Butiran Akaun Bank Penjual dan Nombor tertera',
    payment_to_seller_instruction_4: 'Buat pembayaran dan kemudian kongsikan bukti pembayaran kepada Penjual',
    payment_to_seller_instruction_5: 'Penjual akan menghantar Surat Siaran melalui e-mel kepada anda dan kepada Pickles apabila pembayaran disahkan',
    payment_to_seller_instruction_6: 'Kakitangan Pickles akan meluluskan pembayaran, yang akan dipaparkan pada skrin Butiran item',
    pickles_verify_seller_payment: 'Pembayaran kepada Penjual akan disahkan oleh Pickles',
    pre_bid: 'Prabidaan',
    refund: 'Untuk mengembalikan deposit, sila hantar e-mel kepada refunds@pickles.my dengan nama pengguna dan butiran akaun bank anda. \n\nKami akan proses pembayaran semula selepas menerima butiran anda.',
    reset_pass_email_sent: 'Kami telah menghantar e-mel untuk tetapkan semula kata laluan anda.',
    reset_pass_sent: 'Tetapkan Semula Kata Laluan E-mel berjaya dihantar.',
    reset_password: 'Lupa Kata Laluan?',
    still_problem: 'Sekiranya anda masih menghadapi masalah atau anda belum menerima e-mel kami selepas beberapa minit, hubungi kami di',
    submit_tax_info: 'Maklumat cukai diperlukan untuk tujuan e-Invois dan caj Cukai Jualan dan Perkhidmatan (SST) mengikut arahan LHDN',
    tax_info_not_updatable: 'Sila ambil perhatian bahawa anda tidak akan dapat menukar butiran e-invois daripada apl kemudian.',
    wait_1_hour: 'Akaun anda belum diluluskan lagi. Sila beri masa selama 1 hari untuk pengesahan atau hubungi khidmat pelanggan kami uituk bantuan segera',
    we_will_look: 'dan kami akan menbantu anda.',
    you_must_logged: 'Anda mesti log masuk untuk melihat harga bermula dan membuat bidaan.',
    your_account_has_not: 'Akaun anda belum diluluskan lagi. Sila hubungi khidmat pelanggan kami',
    your_bid_must_be_higher: 'Anda mesti menawar lebih tinggi daripada nilai ini {{value}}',
  },
  status: {
    e_bidding: 'eBidaan',
    live_auction: 'Lelong Secara Langsung',
    lost: 'TIDAK BERJAYA',
    not_current_item: 'Tiada item',
    offer: 'TAWARAN',
    on_hold: 'Item ini masih dipegang / akan dijual untuk untuk lelongan di masa hadapan.',
    outbid_lowercase: 'Outbid',
    outbid: 'TAWARAN DIATASI',
    winning_lowercase: 'Winning',
    winning: 'SEDANG MENANG',
    won: 'MENANG',
  },
  titles: {
    all_auctions: 'Semua jenis lelong',
    apply_payment: 'Tetap Pembayaran',
    auction_detail: 'Senarai Item',
    awesome_news: 'Awesome news!',
    bid_history: 'Rekod tawaran',
    car_details: 'Butiran Item',
    connect_acc: 'Sambungkan akaun',
    custom_bid: 'Bidaan tersendiri',
    dashboard: 'Papan pemuka',
    duplicate_payment_reference: 'Pembayaran pendua',
    ebidding_auctions: 'Lelong eBidding',
    enter_note: 'Catatkan nota',
    error: 'Ralat',
    e_invoicing_details: 'Butiran E-Invois',
    failure: 'Failure!',
    filters: 'Turasan',
    gallery: 'Galeri',
    important_notice: 'Notis Penting!',
    item_photos: 'Gambar-gambar item',
    live_auctions: 'Lelong secara langsung',
    make_payment: 'Membuat Pembayaran',
    my_account: 'Akaun saya',
    my_bidding_activity: 'Aktiviti Pembidaan Saya',
    my_bids: 'Bidaan saya',
    my_offers: 'Tawaran saya',
    my_prebids: 'Prabidaan saya',
    my_prebids_note: 'Hanya dilelong secara langsung',
    my_purchases: 'Rekod Pembelian saya',
    my_purchases_v2: 'Pembelian & Tawaran',
    my_watchlist: 'Senarai pantau saya',
    no_offers: 'Tiada rekod tawaran',
    notes: 'Nota',
    notice: 'Notis',
    offers_history: 'Rekod tawaran',
    offers: 'Tawaran',
    payment_submission_guidelines: 'Garis Panduan Penyerahan Pembayaran',
    payment_success: 'Bukti Pembayaran anda telah berjaya diserahkan',
    pickles_auctions: 'Pickles Auctions',
    pos: 'Proclamation of Sale',
    purchase_details: 'Rekod pembelian',
    purchases: 'Pembelian',
    reset_password: 'Tetapkan semula kata laluan',
    search_result: 'Hasil carian',
    search: 'Cari',
    select_date: 'Pilih tarikh',
    select_items: 'Pilih item',
    sell_with_us: 'Jual dengan kami',
    share: 'Kongsi',
    sorry: 'Maaf!',
    submit_payment_proof: 'Hantar Bukti Pembayaran',
    submit_tax_info: 'Hantar Maklumat Cukai Anda',
    success: 'Success!',
    top_up: 'Tambah Nilai',
    update_require: 'Pastikan aplikasi Pickles dikemas kini!',
    user_offers: 'Tawaran',
    vehicle_list: 'List kenderaan',
  },
  messages: {
    Accepted: 'Diterima',
    AdjustDown: 'Bidan disuaikan',
    CreditExceed: 'Terlebih deposit',
    FlaggedNoBuy: 'Tidak boleh dibeli',
    OtherBid: 'Bida sekali lagi',
    OutBid: 'Bidaan anda lebih rendah!',
    PriorProposal: 'Bida sekali lagi',
    ReppingItem: 'Ini item anda',
    reserve_price_info: 'Item ini tidak tunjukan harga rizab.',
    Reset: 'Bidaan ditetapkan',
    ToFloor: 'Given to floor',
    TooLow: 'Bidaan terlaulu rendah',
  },
  errors: {
    brn_id_my_invalid: 'Nombor Pendaftaran Perniagaan (BRN) mestilah 12 digit',
    contact_support: 'Menghadapi masalah samasa pendaftaran pengguna. Sila hubungi khidmat pelanggan kami',
    email_already_exists: 'Emel sudah digunakan oleh pengguna lain.',
    email_exists: 'Alamat emel ini telah didaftarkan.',
    email_not_register: 'Emel ini tidak berdaftar',
    error_oops: 'Oops!',
    error_title: 'Gagal',
    ic_number_already_exists: 'Nombor kad pengenalan sudah digunakan oleh pengguna lain.',
    ic_number_exists: 'Nombor kad pengenalan ini telah didaftarkan.',
    login_user_failure: 'Anda telah memasukkan nama pengguna yang tidak sah atau kata laluan yang salah.',
    token_expired_error: 'Sesi log masuk anda telah tamat. Sila log masuk semula.',
    auth_error: 'Maaf, terdapat ralat {{errorCode}} berlaku semasa memproses permintaan anda. Sila cuba dalam beberapa saat, atau hubungi {{phone}} jika masalah masih berterusan.',
    client_error: 'Maaf, terdapat ralat {{errorCode}} berlaku semasa memproses permintaan anda. Sila cuba dalam beberapa saat, atau hubungi {{phone}}.',
    server_error: 'Terdapat ralat {{errorCode}} berlaku semasa memproses permintaan anda. Sila cuba dalam beberapa saat, atau hubungi {{phone}}.',
    timeout_error: 'Server is taking too long to respond. Please try in a few moments or contact {{phone}}.',
    connection_error: 'It seems that you do not have an active internet connection. Please try checking your data connection or reconnecting to Wi-Fi. Please contact {{phone}} if the problem still persists.',
    network_error: 'Tidak mempunyai sambungan internet yang aktif. Cuba periksa sambungan data atau sambungkan ke Wi-Fi.',
    cancel_error: 'It seems that your connection has an issue. Please try again.',
    no_internet: 'Tiada Internet',
    offline: 'Offline',
    page_not_found: 'We can’t seem to find the page you’re looking for.',
    please_fill_next_fields: 'Sila isi data yang sah dalam ruangan seterusnya',
    register_another_email: 'Alamat emel yang anda berikan telah didaftarkan. Sila cuba dengan alamat emel yang berbeza.',
    register_another_ic_number: 'Nombor kad pengenalan yang anda berikan telah didaftarkan. Sila cuba dengan nombor kad pengenalan yang berbeza.',
    register_another_username: 'Nama yang anda berikan telah didaftarkan. Sila cuba dengan nama yang berbeza.',
    register_user_failure: 'Kegagalan pendaftaran pengguna ',
    required: 'Medan ini diperlukan',
    server_fields: {
      buyer_type: 'Jenis pembeli',
      username: 'Nama pengguna',
      gov_id: 'Nombor kad pengenelan',
      email: 'Emel',
      phone: 'Nombor telefon',
    },
    still_pending_approval_new: 'Kelulusan akaun mengambil masa 1 hari berkerja. Hubungi {{phone}} untuk kelulusan segera',
    still_pending_approval: 'Akaun anda masih dalam proses kelulusan. Dianggarkan mengambil masa selama 1 hari bekerja. Jika memerlukan kelulusan dengan kadar segera, sila hubungi {{phone}}',
    transaction_limit: 'Tambah nilai dalam talian pada masa ini mempunyai had transaksi tunggal sebanyak {{maxAmount}}. Sila masukkan sebarang jumlah dari {{minAmount}} hingga {{maxAmount}} untuk meneruskan',
    username_already_exists: 'Nama sudah digunakan oleh pengguna lain.',
    username_exists: 'Nama ini telah didaftarkan.',
  },
  transactions: {
    auto_settled: 'Auto-settle',
    cash: 'Tunai',
    check: 'Cek',
    credit_card: 'Kad kredit',
    deposit: 'Tambah Nilai Deposit',
    download: 'Muat turun',
    invoice_buyer: 'Yuran pembeli',
    invoice_consigner: 'Yuran penjual',
    invoice_deposits: 'Item deposit',
    invoice: 'Invois',
    noTransactions: 'Tiada transaksi', // No purchases
    payment_method: 'Cara bayaran',
    payment: 'Pembayaran',
    refund: 'Bayar balik',
    title: 'Rekod transaksi', // Transaction History
    view_older: 'Untuk rekod transaksi yang lebih lama, sila hubungi info@pickles.my',
  },
  socket: {
    latency: 'Latency',
    ebid: {
      connect_error: 'Connection error',
      connected: 'E-Bidding Connected',
      disconnect: 'Connection Lost',
      reconnect_error: 'Reconnecting...',
      reconnect_failed: 'Reconnect failed',
      reconnected: 'Connection established.',
      reconnecting: 'Reconnecting...',
      retries: 'Retries Attempt',
    },
    live: {
      connect_error: 'Connection error',
      connect_timeout: 'Connection timeout',
      connected: 'Live Auction Connected',
      disconnect: 'Connection Lost',
      reconnect_error: 'Reconnecting...',
      reconnect_failed: 'Reconnect failed',
      reconnected: 'Connection established.',
      reconnecting: 'Reconnecting...',
      retries: 'Retries Attempt',
    },
  },
  fpx: {
    amount: 'Amaun',
    b2c: 'Perbankan Peribadi',
    b2b: 'Perbankan Korporat',
    buyer_bank: 'Bank Pembeli',
    cancel: 'Batal',
    close: 'Penutupan',
    fail: 'Gagal',
    online_banking: 'Perbankan Dalam Talian',
    pay_with: 'Bayar dengan',
    payment_mode: 'Mod pembayaran',
    proceed: 'Teruskan',
    refresh: 'Muat semula',
    select_payment_mode: 'Sila pilih mod pembayaran',
    select_bank: 'Sila pilih bank',
    seller_order_number: `Nombor Pesanan Pickles`,
    successful: 'Berjaya',
    transaction_id: 'ID Transaksi FPX',
    transaction_status: 'Status Transaksi',
    transaction_amount: 'Jumlah Transaksi',
    transaction_date: 'Tarikh & Masa Transaksi',
    transaction_limit: 'Tambah nilai melalui FPX mempunyai had transaksi minimum RM 2 dan had maksimum RM 10,000. Sila masukkan sebarang jumlah dari RM 2 hingga RM 10,000 untuk meneruskan',
    tnc_text: 'Dengan mengklik butang "Teruskan", anda dengan ini bersetuju menerima Terma & Syarat FPX',
    tnc_hyperlink: `Terma & Syarat FPX`,
    topup_successful: `Tambah nilai berjaya. {{amount}} telah ditambahkan pada baki anda.`,
    transaction_failed: 'Transaksi gagal',
  }
};
