module.exports = {
  API_URL: 'https://api-test.env.ams.pickles.my/api',
  HOST_URL: 'https://pickles-v2-test.env.ams.pickles.my',
  EBID_ACTIVITY_PAGE_SIZE: 1000,
  EBID_TIMER: true,
  EXIT_ON_JAILBREAK: false,
  PREBID_PAGE_SIZE: 9999,
  MOLPAY_CIPHER_KEY: 'qv@mh+pK}DE1pFtzPDawi?F,?h6R}*VE',
  DASHBOARD_UPCOMING_AUCTIONS_COUNT: 5,
  NETWORK_ERROR_DISPLAY_INTERVAL: 5, // seconds
  IS_OLD_DESIGN: false, // Use v1 design
  STRAPI_URL: 'https://pickles-cms-test.env.ams.pickles.my',
  MAX_DEPOSIT_AMOUNT: 5000, // 5000 RM is the max limit that is allowed by MOLPay
  MIN_DEPOSIT_AMOUNT: 2,
  CAN_ATTEND_LIVE_AUCTION: true,
  AMS_ANALYTICS_API_KEY: 'e3c5fada-648e-4fd2-a45a-e73f49099ad1',
  APPSEARCH: {
    ENABLED: true,
    URL: 'https://ams-appsearch-staging.ent.ap-southeast-1.aws.found.io/api/as/v1/engines/inventory-search',
    TOKEN: 'search-fsvf7y4mxvo9ou7igdoa8v82'
  },
  SENTRY: {
    ENABLED: false,
    AUTH_TOKEN: 'ebcc104573b4489591c83f123f366ef703732ca79442497c8babcc14afa6e534',
    CLIENT_DSN: 'https://5cb26ab764ce4c60982bc40f90e91538@o484306.ingest.sentry.io/6540783',
    SSR_DSN: 'https://6427fb948b8e4054bb46fce2e4140ae7@o484306.ingest.sentry.io/6511631',
    CONTENT_SECURITY_POLICY: 'https://o484306.ingest.sentry.io/api/6511631/security/?sentry_key=6427fb948b8e4054bb46fce2e4140ae7'
  },
  TOGGLES: {
    ENABLE_MY_PURCHASES_V1: false, // To enable old purchases related UI
    ENABLE_MY_PURCHASES_V2: true, // To enable purchases related UI for Post Sale Digitalization (PSD)
  },
  FPX: {
    SELLER_ID: 'SE00028483',
    EXCHANGE_ID: 'EX00021986',
    SELLER_BANK_CODE: '01',
    PRODUCT_DESCRIPTION: 'AccountTopup',
    AR_FORM_URL: 'https://uat.mepsfpx.com.my/FPXMain/seller2DReceiver.jsp',
    TNC_URL: 'https://www.mepsfpx.com.my/FPXMain/termsAndConditions.jsp',
    MOBILE_FORM_REDIRECT_URL: 'https://pickles-v2-test.env.ams.pickles.my/payment/fpx/mobile-redirect',
    MIN_DEPOSIT_AMOUNT: 2,
    MAX_DEPOSIT_AMOUNT: 10000,
  },
  RECAPTCHA_SITE_KEY: '6LdWA6wUAAAAAF4SWwTWx6mFoa65juD9bAx34cGH',
  CODEPUSH_IOS_DEPLOYMENT_KEY: 'L7zFaK1-u1EXabwOYIMcgBBXJC2WYuCttZD2-',
  CODEPUSH_ANDROID_DEPLOYMENT_KEY: '2GVEFvqcMY3MZnzmXAF3n-l4I3TBHA-HMj12u',
  DT_APM_ENABLED: true,
};
